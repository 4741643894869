<template>
  <div class="row">
    <div class="col-12">
      
      <div class="card-box mb-2">
        <!-- map -->
        <div id="googleMap" class="google"></div>
      </div>
    </div>
   
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: "GoogleMap",
  data() {
    const { currentRoute } = router
    return {
      center: { lat: Number, lng: Number },
      address: currentRoute.value.query.addr,
      reload: 0,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          Marker:{},
          InfoWindow:{},
          MapTypeId: {},
        },
      },
    };
  },
  mounted() {
    this.loadGoogleMapsScript().then(() => {
      this.google = window.google;
      this.initializeMap();
    });
  },
  methods: {
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector("#googleMap");
      
      const { Map, Marker,InfoWindow,MapTypeId } = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();

    

    
      var _that=this;
      new Geocoder.geocode({ address: _that.address }, function (results, status) {
        if (status === "OK" && results[0]) {
            // map create
          let map = new Map(mapContainer, {
            zoom: 16,
            mapTypeId: MapTypeId.ROADMAP,
          });
          let locate = results[0].geometry.location;
          _that.center = {
            lat: Number(locate.lat()),
            lng: Number(locate.lng()),
          };

          // set locate
          map.setCenter(_that.center);
          var infowindow=[];
          var markers=[];
           infowindow[0] = new InfoWindow({
                content: '<div id="content">' +
                            '<h3 id="firstHeading" class="firstHeading">'+_that.address+'</h3>' +
                            "</div>"
              });
              
              markers[0]=new Marker({
                position: {
                          lat: Number(locate.lat()),
                          lng: Number(locate.lng())},
                map:map,
                title: _that.address,
                icon: "https://cbredata.s3.ap-northeast-1.amazonaws.com/images/lou.png",
              });
              markers[0].addListener("click", () => {
                infowindow[0].open({
                  anchor: markers[0],
                  map:map,
                  shouldFocus: false
                });
              });
          //pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    }
  },
  watch: {
    reload: function() {
      this.initializeMap()
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 600px;
}
</style>
